body {
  margin: 0;
}
.MuiDrawer-root {
  background-color: #00917d;
  min-height: 100vh;
  margin-right: 20px;
}

.MuiInputBase-formControl {
  color: rgb(41, 41, 41) !important;
  .MuiListItemIcon-root {
    color: rgb(41, 41, 41) !important;
  }
}

.MuiDrawer-root .MuiMenuItem-root {
  color: #fff !important;
  .MuiListItemIcon-root {
    color: #fff !important;
  }
  &.logout {
    color: inherit !important;
    .MuiListItemIcon-root {
      color: inherit !important;
    }
  }
}
